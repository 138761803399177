import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Montserrat"', "sans-serif"].join(","),
    fontWeightBold: 700,
    fontWeightLight: 400,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "8px",
      },
    },
  },
  palette: {
    primary: {
      main: "#01313f",
    },
    secondary: {
      main: "#dc4f1f",
    },
  },
});

export default theme;
