import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    LinearProgress,
    makeStyles,
} from "@material-ui/core";
import {
    AccountBalance,
    Close,
    OpenInNew,
    Visibility,
} from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { getTransactionUrl } from "../../helpers/utility";
import PlanAPI from "../../store/plan/plan.api";
import { fetchPlanMemberRequest } from "../../store/transaction/transaction.actions";
import { getPlanMemberRequests } from "../../store/transaction/transaction.selector";
import Loader from "../base/loader";
import TitleBar from "../base/TitleBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PlanMemberContainer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedItem, setSelecteItem] = useState({});
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [payouts, setPayouts] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    dispatch(
      fetchPlanMemberRequest({
        page: 0,
        pageSize: 10,
      })
    );
  }, []);

  const { planMembers, isLoading } = useSelector((state) => {
    return {
      planMembers: getPlanMemberRequests(state),
      isLoading: state.TransactionReducer.isLoading,
      totalPlanMembers: state.TransactionReducer.totalPlanMembers,
    };
  }, []);

  useEffect(() => {
    setAmount(0);
  }, [planMembers]);

  const actionRenderer = () => {
    return {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => {
          let dep = _.find(planMembers, (x) => x._id === value);
          return (
            <Visibility
              className={classes.icon}
              color="secondary"
              onClick={() => {
                setOpen(true);
                setSelecteItem(dep);
                PlanAPI.GetPlanDetail(value).then((s) => {
                  setPayouts(s.payouts || []);
                  setContracts(s.contracts || []);
                });
              }}
            />
          );
        },
      },
    };
  };

  const columns = [
    {
      name: "memberId",
      label: "Member",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Joined Time",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "maturityDate",
      label: "Maturity Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (moment().diff(moment(value), "second") > 0) {
            return (
              <span style={{ color: "green", fontWeight: 700 }}>
                {moment(value).format("DD-MM-YYYY")}
              </span>
            );
          } else {
            return <span>{moment(value).format("DD-MM-YYYY")}</span>;
          }
        },
      },
    },
    {
      name: "token",
      label: "Tokens",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //     name: 'accumulatedAmount',
    //     label: 'Acc Amount',
    //     options: {
    //         filter: false,
    //         sort: true,
    //     }
    // },
    // {
    //     name: 'id',
    //     label: 'Tentative',
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             let dep = _.find(planMembers, x => x._id === value);
    //             if (moment(dep.createdAt).isBetween("2024-01-01", "2024-02-29")) {
    //                 let updated = parseFloat(Math.round(dep.token * 0.011));
    //                 let diff = dep.amount - updated;
    //                 if (diff < 0) {
    //                     diff = diff * -1;
    //                 }
    //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
    //             }
    //             if (moment(dep.createdAt).isBetween("2024-03-01", "2024-03-16")) {
    //                 let updated = parseFloat(Math.round(dep.token * 0.014));
    //                 let diff = dep.amount - updated;
    //                 if (diff < 0) {
    //                     diff = diff * -1;
    //                 }
    //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
    //             }
    //             else {
    //                 let updated = parseFloat(Math.round(dep.token * 0.018));
    //                 let diff = dep.amount - updated;
    //                 if (diff < 0) {
    //                     diff = diff * -1;
    //                 }
    //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
    //             }
    //         }
    //     }
    // },
    // {
    //     name: 'id',
    //     label: 'id',
    //     options: {
    //         filter: false,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             return <TextField value={amount} onChange={(e) => {
    //                 setAmount(e.target.value);
    //             }}
    //                 variant='outlined'
    //                 margin='dense'
    //                 InputProps={{
    //                     endAdornment: <InputAdornment position='end'>
    //                         <Save onClick={() => {
    //                             dispatch(updatePlanMemberRequest({
    //                                 id: value,
    //                                 amount: amount
    //                             }))
    //                         }} />
    //                     </InputAdornment>
    //                 }}
    //             />
    //         }
    //     }
    // },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //     name: 'payouts',
    //     label: 'Payouts',
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             return value ? _.find(value, s => s.type === "1 Level Income") ? "Yes" : "No" : 0
    //         }
    //     }
    // },
    // {
    //     name: 'id',
    //     label: 'Tentative',
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             let dep = _.find(planMembers, x => x._id === value);
    //             if (moment(dep.createdAt).isBetween("2024-01-01", "2024-02-29")) {
    //                 return Math.round(dep.token * 0.011) + " USD"
    //             }
    //             if (moment(dep.createdAt).isBetween("2024-03-01", "2024-03-16")) {
    //                 return Math.round(dep.token * 0.014) + " USD"
    //             }
    //             else {
    //                 return Math.round(dep.token * 0.018) + " USD"
    //             }
    //         }
    //     }
    // },
    // {
    //     name: 'contracts',
    //     label: 'Hash',
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             return value ? <a href={"https://bscscan.com/tx/" + value[0].transactionHash} target='_blank'> <OpenInNew /> </a> : <></>
    //         }
    //     }
    // },
    actionRenderer(),
  ];

  const datum = _.sortBy(planMembers, (t) => moment(t.createdAt))
    .reverse()
    .map((item) => {
      return {
        id: item._id,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        memberId: item.memberId,
        maturityDate: item.maturityDate,
        token: item.token + " Tokens",
        amount: item.amount + " USD",
        status: item.status,
        payouts: item.payouts,
        contracts: item.contract,
        accumulatedAmount: item.accumulatedAmount,
      };
    });

  const options = {
    selectableRows: false,
    filter: true,
    elevation: 0,
  };

  const selectedPlan = selectedItem;
  let currency = "USD";
  let completed = 0;
  if (selectedPlan) {
    let duration = moment(selectedItem.maturityDate).diff(
      moment(selectedItem.createdAt),
      "day"
    );
    let completed =
      selectedItem.status === "Approved"
        ? moment().diff(moment(selectedItem.createdAt), "day")
        : duration;
    completed = parseInt((completed * 100) / duration);
  }

  let total = 0;
  let reinvest = 0;

  return (
    <div className="mb-4 pb-4">
      {isLoading && <Loader />}
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          Plan Transaction{" "}
          <Close className="mt-1 float-right" onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {selectedPlan && (
            <>
              <ul className="stripe-ul">
                <li>
                  <span>Reference No :</span>
                  <span>{selectedPlan._id}</span>
                </li>
                <li>
                  <span>Joined Date :</span>
                  <span>{moment(selectedPlan.createdAt).format("lll")}</span>
                </li>
                <li>
                  <span>
                    {selectedPlan.status === "Approved"
                      ? "Maturity Date"
                      : "Unstack Date"}{" "}
                    :
                  </span>
                  <span>{moment(selectedPlan.maturityDate).format("ll")}</span>
                </li>
                <li>
                  <span>Duration : </span>
                  <span>
                    {selectedPlan.returns && selectedPlan.returns.length} Months
                  </span>
                </li>
                <li>
                  <span>Amount : </span>
                  <span>{selectedPlan.amount} USD</span>
                </li>
              </ul>
              <LinearProgress
                className="mt-2"
                color="primary"
                style={{ height: 20 }}
                value={completed}
                variant="determinate"
              />

              <table className="table table-striped mt-2">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Deposit Amount</th>
                    <th>Reward Gained</th>
                    <th>Reward Withdrawable</th>
                    <th>Reinvest Amount</th>
                    <th>Withdraw Status</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedPlan.returns &&
                    selectedPlan.returns.map((s, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{s.date}</td>
                          <td>
                            {s.amount} {currency}
                          </td>
                          <td>
                            {s.interest} {currency}
                          </td>
                          <td>
                            {s.withdrawalAmount} {currency}
                          </td>
                          <td>
                            {s.reinvest} {currency}
                          </td>
                          <td>{s.status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
          <Divider className="mb-2" />

          {payouts && (
            <>
              <h4>Payouts</h4>
              <table className="table table-striped mt-2">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Reward</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {payouts &&
                    payouts.map((s, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{s.type}</td>
                          <td>{moment(s.createdAt).format("LLL")}</td>
                          <td>
                            {s.amount} {currency}
                          </td>
                          <td>{s.createdBy}</td>
                          <td>{s.memberId}</td>
                          <td>{s.status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
          <Divider className="mb-2" />
          {selectedPlan && selectedPlan.unStackReturns && (
            <>
              <h4>UnStack Returns</h4>
              <table className="table table-striped mt-2">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>

                    <th>Reinvestment</th>
                    <th>Withdraw Amount</th>
                    <th>Fees</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedPlan.unStackReturns &&
                    selectedPlan.unStackReturns.map((tx) => {
                      total = total + parseFloat(tx.amount);
                      reinvest = reinvest + parseFloat(tx.reinvestment) / 3;
                      return (
                        <tr>
                          <td>{tx.month}</td>
                          <td>{tx.date}</td>
                          <td>
                            {(parseFloat(tx.reinvestment) / 3).toFixed(3)} T369
                          </td>

                          <td>{tx.amount} T369</td>
                          <td>
                            {((parseFloat(tx.amount) * 3) / 100).toFixed(3)}{" "}
                            T369
                          </td>
                          <td>
                            <Button
                              size="small"
                              variant={
                                tx.status === "Paid" ? "text" : "contained"
                              }
                              color="primary"
                              onClick={() => {
                                if (tx.status === "Paid") {
                                  window.open(
                                    getTransactionUrl("BNB") + tx.withdrawHash,
                                    "_blank"
                                  );
                                }
                              }}
                              disabled={
                                moment(tx.date, "DD-MM-YYYY").diff(
                                  moment(),
                                  "second"
                                ) > 0
                              }
                            >
                              {tx.status === "Paid"
                                ? "Paid"
                                : moment(tx.date, "DD-MM-YYYY").diff(
                                    moment(),
                                    "second"
                                  ) < 0
                                ? "Ready"
                                : "Pending"}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <th></th>
                    <th>Total</th>
                    <th>{reinvest.toFixed(3)} T369</th>
                    <th>{total.toFixed(3)} T369</th>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </DialogContent>
        {contracts && (
          <DialogActions>
            {contracts.map((t) => {
              return (
                <a
                  href={"https://bscscan.com/tx/" + t.transactionHash}
                  target="_blank"
                >
                  {" "}
                  <OpenInNew /> {t.method}
                </a>
              );
            })}
          </DialogActions>
        )}
      </Dialog>
      <TitleBar
        heading="PlanMember Transactions"
        secondary={""}
        icon={<AccountBalance />}
      />
      <div className={classes.root}>
        <MUIDataTable
          title={"Member Plans List"}
          data={datum}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
}
